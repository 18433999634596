<div id="view-report" class="right-nav showModal" [ngClass]="{'common-view' :isCommonView, 'inline':isInline}">
  <div (click)="closeModel()" class="empty"></div>
  <div class="component">
    <div class="header border-bottom" *ngIf="!isInline">
      <div class="font-primary-subtitle bold">
        <span *ngIf="report.recordType === 'CLNT' || report.recordType ==='CLHP'">
          {{'ttl_notes'|translate:'LABELS'}}
        </span>
        <span *ngIf="report.recordType === 'RDIN'">
          {{'ttl_radiology'|translate:'LABELS'}}
        </span>
        <span *ngIf="report.recordType === 'LBIN'">
          {{'ttl_laboratory'|translate:'LABELS'}}
        </span>
      </div>
      <div *ngIf="!isCommonView" (click)="closeModel()" class="float-right closeicon pointer">
        <img src="./assets/icons/close_black.svg">
      </div>
    </div>
    <div class="options" *ngIf="!isInline">
      <div class="practitioner font-primary-subtitle bold">
        {{report.patientName || selectedPatient.personname}}
      </div>
      <div class="actions">
        <ngx-qrcode (click)="expandQR()" [elementType]="'url'" [value]="qrcodeUrl.thumbnail" cssClass="asclass"
          errorCorrectionLevel="L">
        </ngx-qrcode>
        <div class="button-style cursor-pointer" [appRemoveFeatureOff]="'FT_APP_HR_DOWNLOADDOCS'"
          (click)="startDownload()">
          <img src="assets/icons/download.svg" />
        </div>
        <div class="button-style cursor-pointer" *ngIf="false" (click)="startDownload()">
          <img class="type-image" src="assets/icons/share.svg" />
        </div>
      </div>
    </div>
    <div class="container-content">
      <div class="details" *ngIf="!isInline">
        <div class="card-layout">
          <div class="card">
            <div class="reportname font-default-subtitle bold">
              <div class="">{{report['eventGroupDesc']}}</div>
              <div class="show">
              <div class="toggle" *ngIf="hasGraph && !query">
                <div class="toggle-button">
                  <div class="button-option cursor-pointer" [ngClass]="{active: view=='table'}" (click)="changeView('table')">
                    <span class="button-text">
                      <img class="inactiveIcon type-image" src="assets/icons/grid.svg" alt="">
                      <img class="activeIcon type-image" src="assets/icons/grid_white.svg" alt="">
                      <!-- Table -->
                    </span>
                  </div>
                  <div class="button-option cursor-pointer" [ngClass]="{active: view=='graph'}" (click)="changeView('graph')">
                    <span class="button-text">
                      <img class="inactiveIcon type-image" src="assets/icons/graph.svg" alt="">
                      <img class="activeIcon type-image" src="assets/icons/graph_white.svg" alt="">
                      <!-- Graph -->
                    </span>
                  </div>
                </div>
              </div>
            </div>
            </div>
            <div class="report-details font-light-small">
              <ng-container *ngIf="(report['practitionerId'] | partitionerDetails:report['facilityId']:'resourceName'| translate : 'LOCALE' : 'name') || report['performedBy'] as practName">{{practName}},</ng-container>
              <ng-container *ngIf="(report['facilityId']| facilityDetails : 'facilityName'| translate : 'LOCALE' : 'name') || report['facilityName'] as facilityName">{{facilityName}},</ng-container>
              <ng-container *ngIf="(report['facilityId']| facilityDetails : 'facilityAddress'| translate : 'LOCALE' : 'name') || report['facilityAddress'] as facilityAddress">{{facilityAddress}},</ng-container>
              {{report['reportDateTime']| moment : 'DD MMM YYYY, hh:mm a'}}
            </div>
          </div>
        </div>
      </div>
      <div class="toggle hide" *ngIf="hasGraph && !query">
        <div class="toggle-button">
          <div class="button-option cursor-pointer" [ngClass]="{active: view=='table'}" (click)="changeView('table')">
            <span class="button-text">
              <img class="inactiveIcon type-image" src="assets/icons/grid.svg" alt="">
              <img class="activeIcon type-image" src="assets/icons/grid_white.svg" alt="">
              {{'table'|translate:'LABELS'}}
            </span>
          </div>
          <div class="button-option cursor-pointer" [ngClass]="{active: view=='graph'}" (click)="changeView('graph')">
            <span class="button-text">
              <img class="inactiveIcon type-image" src="assets/icons/graph.svg" alt="">
              <img class="activeIcon type-image" src="assets/icons/graph_white.svg" alt="">
              {{'graph'|translate:'LABELS'}}
            </span>
          </div>
        </div>
      </div>
      <div class="clob">
        <div class="card-layout">
          <div class="card" *ngIf="view=='table'">
              <app-report-table [reportEvents]="reportData.Events" [hasGraph]="hasGraph" [query]="query"
                [patientMetaData]="patientMetaData">
              </app-report-table>
          </div>
          <div *ngIf="view=='graph'">
            <app-report-graph [reportEvents]="reportData.Events"></app-report-graph>
          </div>
        </div>
        <div class="eventHistoryPreview" *ngIf="eventHistory">
          <app-report-graph [reportEvents]="[eventHistory]"></app-report-graph>
          <div (click)="closeEventHistory()" class="float-right closeicon pointer close-icon">
            <img class="type-image" src="./assets/icons/close_white.svg">
          </div>
        </div>
      </div>
      <div class="attachments" *ngIf="report.attachments">
        <div class="card-layout">
          <div class="card">
            <div class="font-default-subtitle bold">
              {{'lbl_report_attachment'|translate:'LABELS'}}
            </div>
            <div class="font-light-small">
              {{'lbl_report_attachment_warning'|translate:'LABELS'}}
            </div>
            <div class="attachment-list">
              <ng-container *ngFor="let attachment of report.attachments">
                <ng-container class="images" *ngIf="attachment.type=='images'">
                  <ng-container class="images thumbnail" *ngFor="let files of attachment.files">
                    <img class="type-image" [src]="files.thumb || 'assets/icons/chat/attachment.svg'" class="cursor-pointer" (click)="openPreviewImage(files.image)" />
                  </ng-container>
                </ng-container>
                <ng-container class="images" *ngIf="attachment.type=='video'">
                  <ng-container class="images thumbnail" *ngFor="let files of attachment.files">
                    <img class="type-image" [src]="files.thumb || 'assets/icons/chat/attachment.svg'" class="cursor-pointer"
                      (click)="openPreviewVideo(files.url)" />
                  </ng-container>
                </ng-container>
                <ng-container class="images" *ngIf="attachment.type=='document'">
                  <ng-container class="images thumbnail" *ngFor="let files of attachment.files">
                    <img class="type-image" [src]="files.thumb || 'assets/icons/chat/attachment.svg'" class="cursor-pointer" (click)="openPreviewPdf(files.pdf || files.url)" />
                  </ng-container>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="previewImage" *ngIf="isPreviewImage">
  <img class="type-image" [src]="previewUrl" />
  <div (click)="closePreviewImage()" class="float-right closeicon pointer close-icon">
    <img class="type-image" src="./assets/icons/close_white.svg">
  </div>
</div>
<div class="videoPreview" *ngIf="isPreviewVideo">
  <video controls poster="/images/w3html5.gif">
    <source [src]="previewUrl">
    Your browser does not support the video tag.
  </video>
  <div (click)="closePreviewVideo()" class="float-right closeicon pointer close-icon">
    <img class="type-image" src="./assets/icons/close_white.svg">
  </div>
</div>
<div class="pdfPreview" *ngIf="isPreviewPdf">
  <!-- {{previewUrl}} -->
  <app-file-preview [file]="{mimeType: 'application/pdf', filePath: previewUrl, resolved: true}" (close)="closePreviewPdf()"></app-file-preview>
  <!-- <iframe [src]="previewUrl | safe : 'resourceUrl'" height="100vh"
    width="100vh"></iframe>
  <div (click)="closePreviewPdf()" class="float-right closeicon pointer close-icon">
    <img class="type-image" src="./assets/icons/close_white.svg">
  </div> -->
</div>
<div class="qrPreview" *ngIf="expandQRCode">
  <ngx-qrcode [elementType]="'url'" [value]="qrcodeUrl.location" cssClass="abclass" errorCorrectionLevel="L">
  </ngx-qrcode>
  <div (click)="expandQR()" class="float-right closeicon pointer close-icon">
    <img class="type-image" src="./assets/icons/close_white.svg">
  </div>
</div>