import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ReportService } from '../../services/report.service';
@Component({
  selector: 'app-file-pdfviewer',
  templateUrl: './file-pdfviewer.component.html',
  styleUrls: ['./file-pdfviewer.component.scss']
})
export class FilePdfviewerComponent implements OnInit {
  @Input() url;
  @Input() resolved: boolean = false;
  public blobUrl
  constructor(private http: HttpClient, private report: ReportService, private cdf: ChangeDetectorRef) { }

  ngOnInit(): void {
    if (this.resolved) {
      this.blobUrl = this.url
      return;
    }
    this.report.resolveFileURL({ paths: this.url }).subscribe(result => {
      this.blobUrl = result[0]
    })
  }

  public error(event) {
    console.error(event)
  }

  public stopLoader(ev) {
    console.log('stop loader', ev)
    setTimeout(_ => {
      this.cdf.detectChanges()
    }, 1000)
  }

  

}
