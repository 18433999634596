<div class="empty" (click)="closeModel()"></div>
<div class="file-preview-wrapper">
  <div class="container header">
    <div class="row">
      <div class="col-10">
        <div class="float-left bold no-margin title">
          <img src="assets/icons/chat/attachment.svg" width="20px" />
          {{file['name']}}
        </div>
      </div>
      <div class="col-2">
        <div (click)="closeModel()" class="float-right closeicon cursor-pointer">
          <img src="./assets/icons/chat/close.svg">
        </div>
      </div>
    </div>
  </div>
  <div class="content">
    <ng-container *ngIf="!loading">
      <div class="img-wrapper" *ngIf="format == 'IMAGE'">
        <img [src]="file['filePublicLink']" alt="">
      </div>
      <div class="pdf-viewer">
        <app-file-pdfviewer [url]="file['filePath']" [resolved]="file.resolved" *ngIf="format == 'PDF'"></app-file-pdfviewer>
      </div>
      <iframe [src]="docUrl" *ngIf="format == 'DOC'"></iframe>
    </ng-container>
  </div>
</div>